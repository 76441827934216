<template>
  <v-container id="inquiry" fluid tag="section">
    <v-container v-if="!loading && !notFound"
      ><v-tabs
        v-model="activeTab"
        light
        grow
        show-arrows
        active-class="active"
        v-if="(viewMode && !isNew) || editMode"
      >
        <v-tabs-slider class="tabs-slider"></v-tabs-slider>

        <v-tab href="#project">
          <base-icon
            width="40"
            height="23"
            viewBox="0 0 29 29"
            :active="activeTab === 'project'"
            v-slot="props"
          >
            <project-board :fill="props.slotColor" />
          </base-icon>
          &nbsp;About
        </v-tab>

        <v-tab href="#suppliers">
          <base-icon
            width="40"
            height="23"
            viewBox="0 0 28 24"
            :active="activeTab === 'suppliers'"
            v-slot="props"
          >
            <shipping-truck :fill="props.slotColor" />
          </base-icon>
          &nbsp;Suppliers
        </v-tab>

        <v-tab href="#bom" :disabled="!isVerified" v-bind="attrs" v-on="on">
          <base-icon
            width="30"
            height="23"
            viewBox="0 0 30 29"
            :active="activeTab === 'bom'"
            v-slot="props"
          >
            <bom-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;BOM
        </v-tab>

        <v-tab href="#trace" :disabled="!isVerified" v-bind="attrs" v-on="on">
          <base-icon
            width="40"
            height="23"
            viewBox="0 0 23 24"
            :active="activeTab === 'trace'"
            v-slot="props"
          >
            <trace-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;Product Passport
        </v-tab>

        <v-tab
          href="#styleFile"
          :disabled="!isVerified"
          v-bind="attrs"
          v-on="on"
        >
          <base-icon
            width="29"
            height="23"
            viewBox="0 0 29 29"
            :active="activeTab === 'styleFile'"
            v-slot="props"
          >
            <clothing-services :fill="props.slotColor" />
          </base-icon>
          &nbsp;Style File
        </v-tab>

        <v-tab
          href="#costSheet"
          :disabled="!isVerified"
          v-bind="attrs"
          v-on="on"
        >
          <base-icon
            width="40"
            height="23"
            viewBox="0 0 30 28"
            :active="activeTab === 'costSheet'"
            v-slot="props"
          >
            <cost-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;Cost
        </v-tab>

        <v-tab v-if="isAllowedToChangeInquiry" href="#options">
          <base-icon
            width="29"
            height="23"
            viewBox="0 0 26 26"
            :active="activeTab === 'options'"
            v-slot="props"
          >
            <options-icon :fill="props.slotColor" />
          </base-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-if="(viewMode && !isNew) || editMode"
        class="tabs-items"
        v-model="activeTab"
      >
        <v-tab-item value="project">
          <v-card flat class="service_card_container">
            <v-col cols="12">
              <md-content>
                <div id="projectDetails">
                  <div
                    class="md-subheading mb-5 subheading_color py-3 white--text"
                  >
                    Project Details
                  </div>
                  <v-row>
                    <v-col class="form-label">
                      <label>Style Number:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="styleNumber">{{
                        model.styleNumber
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Created at:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="created-at">{{
                        $moment(model.createdAt).format("HH:mm Do MMMM Y")
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row v-if="model.createdAt !== model.updatedAt">
                    <v-col class="form-label">
                      <label>Updated at:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="created-at">{{
                        $moment(model.updatedAt).format("HH:mm Do MMMM Y")
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Start date:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="start-date">{{
                        model.startDate
                          ? $moment(model.startDate).format("Do MMMM Y")
                          : ""
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>End date:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="end-date">{{
                        model.endDate
                          ? $moment(model.endDate).format("Do MMMM Y")
                          : ""
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Style Name:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="styleName">{{
                        model.styleName
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Product Type:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="productType">{{
                        getProductTypeName(model.productType)
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Delivery Destination:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="category">{{
                        model.deliveryDestination
                      }}</label>
                    </v-col>
                  </v-row>
                  <div
                    v-focus
                    class="md-subheading my-5 subheading_color py-3 white--text"
                  >
                    Project Quantity
                  </div>
                  <v-row>
                    <v-col class="form-label">
                      <label>Quantity:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="quantity">{{
                        model.quantity
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Number Of Colours:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="noOfColors">{{
                        model.noOfColors
                      }}</label>
                    </v-col>
                  </v-row>
                  <div
                    class="md-subheading my-5 subheading_color py-3 white--text"
                  >
                    Project Additionals
                  </div>

                  <!-- <v-row>
                    <v-col class="form-label">
                      <label>Services Required:</label>
                    </v-col>
                    <v-col class="form-value font-weight-bold">
                      <ul>
                        <li
                          v-for="(service, index) in inquiryServices"
                          :key="service"
                        >
                          {{ service }}:
                          {{ model.selectedServicesDeadlines[index] }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row> -->

                  <v-row>
                    <v-col class="form-label">
                      <label>Currency:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label class="font-weight-bold" id="currency">{{
                        getCurrencyName(model.currency)
                      }}</label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="form-label">
                      <label>Note:</label>
                    </v-col>
                    <v-col cols="6" class="form-value">
                      <label
                        class="font-weight-bold"
                        id="additionalInformation"
                        >{{ showMoreText }}</label
                      >
                      <v-col>
                        <md-button
                          v-if="
                            model.additionalInformation &&
                            model.additionalInformation.length > 50
                          "
                          @click="showMore = !showMore"
                          >{{ showMore ? "Show Less" : "Show More..." }}
                        </md-button>
                      </v-col>
                    </v-col>
                  </v-row>

                  <!-- <div v-if="assignedSuppliers.length > 0">
                    <div
                      class="md-subheading my-5 subheading_color py-3 white--text"
                    >
                      Assigned Suppliers
                    </div>
                    <div v-if="assignedSuppliers.length > 0">
                      <v-row
                        v-for="supplier in assignedSuppliers"
                        :key="supplier.supplier_id"
                      >
                        <v-col cols="6" class="form-label">
                          <div class="md-subhead">
                            {{ supplier.first_name + " " + supplier.last_name }}
                          </div>
                        </v-col>
                        <v-col cols="6" class="form-value">
                          <router-link
                            :to="'/app/business_profile/' + supplier.id"
                          >
                            <div>
                              {{ supplier.brand_name }}
                            </div>
                          </router-link>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <v-row>
                        <v-col cols="6" class="form-label"></v-col>
                        <v-col cols="6" class="form-value">
                          <label>No suppliers assigned yet.</label>
                        </v-col>
                      </v-row>
                    </div>
                  </div> -->
                </div>
              </md-content>
            </v-col>
          </v-card>
        </v-tab-item>
        <v-tab-item value="suppliers">
          <v-card flat class="service_card_container">
            <SupplierAssignment
              @click="assignSuppliers"
              @supplier-assigned="refreshSuppliers"
              @value-chain-assigned="updateAssignedValueChain"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item value="bom">
          <bom @change-tab="changeTab" @copy-link="copyBomLink" />
        </v-tab-item>
        <v-tab-item value="trace">
          <NonConsumerTraceForm
            v-if="traceForm"
            :traceLayout="traceLayout"
            :brandRegistry="collectionDetails?.brand_organization"
            :assignedValueChain="assignedValueChain"
            @show-trace="openTraceView"
            @change-tab="changeTab"
            @value-chain-assigned="updateAssignedValueChain"
          />
        </v-tab-item>
        <v-tab-item value="styleFile">
          <StyleFile
            :collectionBrand="collectionDetails?.brand_organization"
            :collectionCreatedBy="collectionDetails?.created_by_organization"
            @copy-link="copyStyleFileLink"
          />
        </v-tab-item>
        <v-tab-item value="costSheet">
          <cost-sheet @change-tab="changeTab" />
        </v-tab-item>
        <!-- Services tab hide for saas cycle 1 -->
        <!-- <v-tab-item value="services" v-if="isActive">
          <v-card flat class="service_card_container service_buttons_container">
            <v-row justify="center">
              <v-row
                justify="center"
                class="error_message"
                v-if="
                  !isOwner() &&
                    (!getIsAccountManager() || !isAssignedAccountManager()) &&
                    !showTechPack &&
                    !showFabricForm
                "
              >
                <h3>No services available</h3>
              </v-row>
              <v-row justify="center" v-else>
                <button-card
                  class="service_button_card"
                  id="techPackDevelopmentButton"
                  @click="saveAndTechPackDevelopment"
                  v-if="
                    isOwner() ||
                      (isAssignedToTechPack &&
                        isAssignedSupplier &&
                        showTechPack) ||
                      getIsAccountManager() ||
                      isAssignedAccountManager()
                  "
                >
                  <div class="button_card_items">
                    <ellipse-icon class="button_card_icon"
                      ><tech-pack-icon
                    /></ellipse-icon>
                    <span>Tech Pack Development</span>
                    <md-icon
                      v-if="TechPackCompleted"
                      class="ml-6 techpack_completed_color"
                      >&nbsp;&nbsp;check_circle_outline</md-icon
                    >
                    <md-icon
                      v-else-if="TechPackStarted"
                      class="ml-6 pending_color"
                      >&nbsp;&nbsp;pending_actions</md-icon
                    >
                    <md-icon v-else class="ml-6"
                      >&nbsp;&nbsp;not_started</md-icon
                    >
                  </div>
                </button-card>
                <button-card
                  class="service_button_card"
                  id="techPackDevelopmentButton"
                  @click="openFabricSourcingForm"
                  v-if="
                    isOwner() ||
                      (isAssignedToTechPack &&
                        isAssignedSupplier &&
                        showFabricForm) ||
                      getIsAccountManager() ||
                      isAssignedAccountManager()
                  "
                >
                  <div class="button_card_items">
                    <ellipse-icon class="button_card_icon"
                      ><fabric-icon
                    /></ellipse-icon>
                    <span>Fabric Sourcing Form</span>
                    <md-icon
                      v-if="showFabricForm"
                      class="ml-6 techpack_completed_color"
                      >&nbsp;&nbsp;check_circle_outline</md-icon
                    >
                    <md-icon
                      v-else-if="FabricStarted"
                      class="ml-6 pending_color"
                      >&nbsp;&nbsp;pending_actions</md-icon
                    >
                    <md-icon v-else class="ml-6"
                      >&nbsp;&nbsp;not_started</md-icon
                    >
                  </div>
                </button-card>
              </v-row>
            </v-row>
          </v-card>
        </v-tab-item> -->
        <v-tab-item v-if="isAllowedToChangeInquiry" value="options">
          <v-card flat class="service_card_container service_buttons_container">
            <v-row justify="center">
              <div v-if="showOptions">
                <!-- <v-row
                  justify="center"
                  class="error_message"
                >
                  <h3>No options available</h3>
                </v-row> -->
                <v-row justify="center">
                  <!-- Status Change button hide for saas cycle 1 -->
                  <!-- <div class="update_status_container">
                    <button-card
                      class="service_button_card"
                      v-if="isOwner() || isAssignedAccountManager()"
                      @click="toggleStatusWindow(!updateStatusOpen)"
                    >
                      <div class="button_card_items">
                        <ellipse-icon class="button_card_icon"
                          ><update-status
                        /></ellipse-icon>
                        <span>Update Status</span>
                      </div>
                    </button-card>
                    <div
                      class="update_status_dropdown"
                      v-if="updateStatusOpen"
                      v-click-outside="closeStatusWindow"
                    >
                      <v-list>
                        <v-list-item v-if="getStatus() !== 'active'" link>
                          <v-list-item-title
                            @click="updateInquiryStatus(model.id, 'active')"
                            >ACTIVE
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="getStatus() !== 'inactive'" link>
                          <v-list-item-title
                            @click="updateInquiryStatus(model.id, 'inactive')"
                            >INACTIVE
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="getStatus() !== 'completed'" link>
                          <v-list-item-title
                            @click="updateInquiryStatus(model.id, 'completed')"
                            >COMPLETED
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div> -->

                  <button-card
                    class="service_button_card"
                    id="editInquiryButton"
                    @click="switchEditMode"
                    v-if="isAllowedToChangeInquiry"
                  >
                    <div class="button_card_items">
                      <ellipse-icon class="button_card_icon"
                        ><edit-inquiry
                      /></ellipse-icon>
                      <span>Edit Style</span>
                    </div>
                  </button-card>

                  <!-- <button-card
                      class="service_button_card"
                      id="assignAccountManagerButton"
                      @click="assignAccountManager"
                      v-if="getIsAccountManager() && getIsNotAssignedToManager()"
                    >
                      <div class="button_card_items">
                        <ellipse-icon class="button_card_icon"><assign-icon /></ellipse-icon>
                        <span>Assign to Me</span>
                      </div>
                    </button-card> -->

                  <!-- Duplicate Style button hide for saas cycle 1 -->
                  <!-- <button-card
                    class="service_button_card"
                    id="duplicateInquiryButton"
                    @click="openCollectionForDuplicate"
                    v-if="isActive && (isOwner() || isAssignedAccountManager())"
                  >
                    <div class="button_card_items">
                      <ellipse-icon class="button_card_icon"
                        ><duplicate-icon
                      /></ellipse-icon>
                      <span>Duplicate Style</span>
                    </div>
                  </button-card> -->

                  <!-- Change Collection button hide for saas cycle 1 -->
                  <!-- <button-card
                      class="service_button_card"
                      @click="openChangeCollection"
                      v-if=" isOwner() || (getIsAccountManager() && isAssignedAccountManager())"
                    >
                      <div class="button_card_items">
                        <ellipse-icon class="button_card_icon button_icon">
                          <v-icon>mdi-briefcase-arrow-left-right-outline</v-icon>
                        </ellipse-icon>
                        <span>Change Collection</span>
                      </div>
                    </button-card> -->
                  <button-card
                    class="service_button_card"
                    variant="danger-theme"
                    id="deleteInquiryButton"
                    v-if="isAllowedToChangeInquiry"
                    @click="deleteInquiry(model.id)"
                  >
                    <div class="button_card_items">
                      <ellipse-icon
                        class="button_card_icon"
                        variant="danger"
                        v-slot="props"
                        ><delete-icon :fill="props.fill"
                      /></ellipse-icon>
                      <span>Delete Style</span>
                    </div>
                  </button-card>

                  <!--                  <button-card-->
                  <!--                    class="service_button_card"-->
                  <!--                    @click="selectStyleNumber"-->
                  <!--                    v-if="getIsAccountManager() && isAssignedAccountManager()"-->
                  <!--                    id="importTimelineButton"-->
                  <!--                  >-->
                  <!--                    <div class="button_card_items">-->
                  <!--                      <ellipse-icon class="button_card_icon"-->
                  <!--                        ><style-icon />-->
                  <!--                        <v-icon-->
                  <!--                          style="color:#582963; margin-left:3px;  margin-bottom: 1px;"-->
                  <!--                          large-->
                  <!--                          >library_add</v-icon-->
                  <!--                        >-->
                  <!--                      </ellipse-icon>-->
                  <!--                      <span>Import Timeline</span>-->
                  <!--                    </div>-->
                  <!--                  </button-card>-->

                  <!-- Accept / Reject button hide for saas cycle 1 -->
                  <!-- <button-card
                    class="service_button_card"
                    variant="danger-theme"
                    id="approveOrReject"
                    @click="confirmDialog = true"
                    v-if="
                      isAssignedAccountManager() &&
                        model.inquiryStatus === 'pending_deleted_approval'
                    "
                  >
                    <div class="button_card_items">
                      <ellipse-icon
                        class="button_card_icon"
                        variant="danger"
                        v-slot="props"
                        ><delete-icon :fill="props.fill"
                      /></ellipse-icon>
                      <span>Accept/ Reject</span>
                    </div>
                  </button-card> -->
                </v-row>
              </div>

              <v-col cols="12" lg="9" v-if="editMode">
                <form novalidate class="inquiry-form" @submit.prevent>
                  <md-card-content>
                    <div class="form-container">
                      <md-steppers
                        class="stepper_number_color"
                        :md-active-step.sync="active"
                        md-linear
                        @md-changed="scrollToTop"
                      >
                        <md-step
                          id="first"
                          md-label="Product"
                          :md-error="firstStepError"
                          :md-done.sync="first"
                        >
                          <div
                            class="info-container"
                            style="
                              display: flex;
                              justify-content: space-between;
                              align-items: flex-start;
                            "
                          >
                            <div
                              class="md-layout-item"
                              style="
                                margin-top: 70px;
                                flex-basis: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                              "
                            >
                              <div
                                class="md-layout-item mb-3"
                                style="width: 100%"
                              >
                                <md-field id="product-name" style="width: 100%">
                                  <label for="product_name">Style Name</label>
                                  <md-input
                                    type="text"
                                    name="styleName"
                                    v-model="model.styleName"
                                    maxlength="255"
                                  />
                                </md-field>
                              </div>

                              <div
                                class="md-layout-item mb-3"
                                style="width: 100%"
                              >
                                <v-select
                                  class="selection-field"
                                  v-model="model.productType"
                                  :items="productTypes"
                                  label="Product Type"
                                  dense
                                  outlined
                                  item-value="id"
                                  color="#442D65"
                                  :error-messages="productTypeValidationError"
                                  :disabled="saving"
                                >
                                  <template v-slot:item="{ item }">
                                    {{ item.name }}
                                  </template>
                                  <template v-slot:selection="{ item }">
                                    <span>{{ item.name }}</span>
                                  </template>
                                </v-select>
                              </div>
                            </div>

                            <div
                              class="md-layout-item"
                              style="
                                flex-basis: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                              "
                            >
                              <div>
                                <div
                                  class="lable_size"
                                  style="
                                    padding-top: 0px;
                                    margin-left: 30px;
                                    text-align: center;
                                  "
                                >
                                  <label>Style Picture</label>
                                </div>
                                <div
                                  class="image-container"
                                  style="text-align: center"
                                >
                                  <div class="image-child-container">
                                    <div>
                                      <div
                                        @click="openFileInput()"
                                        class="add-img"
                                        style="cursor: pointer"
                                      >
                                        <v-icon class="add-img-icon"
                                          >add_photo_alternate</v-icon
                                        >
                                      </div>
                                      <input
                                        id="fileInput"
                                        ref="fileInput"
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        @change="
                                          uploadThumbnail($event.target.files)
                                        "
                                      />
                                      <img
                                        v-if="selectedThumbnail"
                                        :src="selectedThumbnail"
                                        alt="Selected image"
                                        class="image mt-1"
                                        style="
                                          max-width: 100%;
                                          height: auto;
                                          margin-top: 10px;
                                        "
                                      />
                                      <embed
                                        v-else
                                        :src="
                                          defaultImage
                                            ? `${baseUrl}/api/inquiries/file_show/${defaultImage}`
                                            : sampleImage
                                        "
                                        alt="sample image"
                                        class="image mt-1"
                                        style="
                                          max-width: 100%;
                                          height: auto;
                                          margin-top: 10px;
                                        "
                                      />
                                      <div v-if="uploading">
                                        <md-progress-spinner
                                          class="color_stroke"
                                          md-mode="indeterminate"
                                        ></md-progress-spinner>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Continue Button -->
                          <div
                            style="
                              margin-top: 20px;
                              margin-left: -100px;
                              text-align: right;
                              width: 100%;
                            "
                          >
                            <md-button
                              class="md-raised btn-color"
                              @click="
                                if (
                                  $v.model.productType.$invalid ||
                                  $v.model.collectionType === 'own' ||
                                  ($v.model.collectionType === 'others' &&
                                    $v.model.collection.$invalid)
                                ) {
                                  setError('first');
                                  setErrorStep1();
                                } else {
                                  setDone('first', 'second');
                                }
                              "
                            >
                              Continue
                            </md-button>
                          </div>
                        </md-step>

                        <md-step
                          id="second"
                          md-label="Project"
                          :md-error="secondStepError"
                          :md-done.sync="second"
                        >
                          <div class="info-container">
                            <div
                              class="md-layout-item md-small-size-100 container-alignment"
                            >
                              <md-field
                                :class="getValidationClass('quantity')"
                                id="quantity"
                              >
                                <label for="quantity"
                                  >Bulk Order Quantity</label
                                >
                                <md-input
                                  type="number"
                                  min="50"
                                  max="100000000"
                                  name="quantity"
                                  v-model="model.quantity"
                                  :disabled="saving"
                                />

                                <span
                                  class="md-error"
                                  v-if="quantityValidationError"
                                  >{{ quantityValidationError }}</span
                                >
                              </md-field>
                            </div>
                            <div
                              class="md-layout-item md-small-size-100 container-alignment"
                            >
                              <md-field
                                :class="getValidationClass('noOfColors')"
                                id="noOfColors"
                              >
                                <label for="no_of_colors"
                                  >Number of Colors</label
                                >
                                <md-input
                                  type="number"
                                  min="1"
                                  max="50"
                                  name="noOfColors"
                                  v-model="model.noOfColors"
                                  :disabled="saving"
                                />
                                <span
                                  class="md-error"
                                  v-if="noOfColorsValidationError"
                                  >{{ noOfColorsValidationError }}</span
                                >
                              </md-field>
                            </div>
                            <div class="md-layout-item md-small-size-100 mt-1">
                              <v-menu
                                v-model="startDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="model.startDate"
                                    label="Start Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="startDateValidationError"
                                    dense
                                    outlined
                                    color="#442D65"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="model.startDate"
                                  @input="startDateMenu = false"
                                  :max="model.endDate"
                                ></v-date-picker>
                              </v-menu>
                              <v-menu
                                v-model="endDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="model.endDate"
                                    label="End Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :error-messages="endDateValidationError"
                                    dense
                                    outlined
                                    color="#442D65"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="model.endDate"
                                  @input="endDateMenu = false"
                                  :min="model.startDate"
                                ></v-date-picker>
                              </v-menu>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                              <v-select
                                class="selection-field"
                                v-model="model.currency"
                                :items="currencies"
                                label="Currency"
                                dense
                                outlined
                                item-value="currency_code"
                                color="#442D65"
                                :error-messages="currencyValidationError"
                                :disabled="saving"
                              >
                                <template v-slot:item="{ item }">
                                  {{ item.display_name }}
                                </template>
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.display_name }}</span>
                                </template>
                              </v-select>
                            </div>
                            <div class="md-layout-item md-small-size-100">
                              <v-autocomplete
                                class="selection-field"
                                v-model="model.deliveryDestination"
                                :items="countries"
                                label="Delivery Destination"
                                dense
                                outlined
                                color="#442D65"
                                :error-messages="
                                  deliveryDestinationValidationError
                                "
                                :disabled="saving"
                              >
                                <template v-slot:item="{ item }">
                                  {{ item }}
                                </template>
                                <template v-slot:selection="{ item }">
                                  <span>{{ item }}</span>
                                </template>
                              </v-autocomplete>
                            </div>

                            <md-card-actions style="padding: 8px 0px">
                              <md-button
                                class="md-raised btn-color"
                                @click="
                                  if (
                                    $v.model.noOfColors.$invalid ||
                                    $v.model.quantity.$invalid ||
                                    $v.model.currency.$invalid ||
                                    $v.model.deliveryDestination.$invalid
                                  ) {
                                    setError('second');
                                    setErrorStep2();
                                  } else {
                                    processContinue();
                                  }
                                "
                                >Continue
                              </md-button>
                            </md-card-actions>
                          </div>
                        </md-step>

                        <md-step
                          id="third"
                          md-label="Additional Information"
                          :md-error="thirdStepError"
                          :md-done.sync="third"
                        >
                          <div class="info-container">
                            <div class="md-layout-item md-small-size-100">
                              <div class="attachments-label mb-3">
                                <label for="additional-information"
                                  >Note:</label
                                >
                              </div>
                              <md-field
                                :class="
                                  getValidationClass('additionalInformation')
                                "
                                id="additionalInformation"
                              >
                                <md-textarea
                                  name="additionalInformation"
                                  id="additional-information"
                                  v-model="model.additionalInformation"
                                  :disabled="saving"
                                ></md-textarea>
                                <span
                                  class="md-error"
                                  v-if="additionalInformationValidationError"
                                >
                                  {{ additionalInformationValidationError }}
                                </span>
                              </md-field>
                            </div>

                            <md-card-actions style="padding: 8px 0px">
                              <md-button
                                id="saveButton"
                                class="md-raised btn-color"
                                :disabled="saving"
                                @click="
                                  if ($v.model.additionalInformation.$invalid) {
                                    setError('third');
                                    setErrorStep3();
                                  } else {
                                    save();
                                  }
                                "
                              >
                                Save
                              </md-button>
                            </md-card-actions>
                          </div>
                        </md-step>
                      </md-steppers>
                    </div>
                  </md-card-content>
                </form>
                <v-dialog v-model="exceededDialog" persistent max-width="400">
                  <v-card>
                    <v-card-text class="text-h3">
                      Style quantity changed
                    </v-card-text>
                    <div class="text-h5">Would you like to continue</div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="purple darken-1"
                        text
                        @click="resetQuantity"
                      >
                        Disagree
                      </v-btn>
                      <v-btn
                        color="purple darken-1"
                        text
                        @click="agreeStatus()"
                      >
                        Agree
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="subceededDialog" persistent max-width="400">
                  <v-card>
                    <v-card-text class="text-h3">
                      Style quantity changed
                    </v-card-text>
                    <div class="text-h5">Would you like to continue</div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="purple darken-1"
                        text
                        @click="resetQuantity"
                      >
                        Disagree
                      </v-btn>
                      <v-btn
                        color="purple darken-1"
                        text
                        @click="agreeStatus()"
                      >
                        Agree
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <!--              <v-col cols="12" lg="9" v-if="importTimeline">-->
              <!--                <div>-->
              <!--                    <v-autocomplete-->
              <!--                      type="text"-->
              <!--                      label="Style Number"-->
              <!--                      v-model="timelineStyleNumber"-->
              <!--                      :items="styleNumbers"-->
              <!--                      item-text="style_number"-->
              <!--                      item-value="id"-->
              <!--                    >-->
              <!--                    </v-autocomplete>-->
              <!--                  <v-btn @click="openTimelineWarning=true" :disabled="!timelineStyleNumber">-->
              <!--                    Import-->
              <!--                  </v-btn>-->
              <!--                </div>-->
              <!--                <v-dialog v-model="openTimelineWarning" persistent max-width="400">-->
              <!--                  <v-card>-->
              <!--                    <v-card-text class="text-h3">-->
              <!--                      This action will add new critical path and remove current critical-->
              <!--                      path.-->
              <!--                    </v-card-text>-->
              <!--                    <div class="text-h5">-->
              <!--                      Would you like to continue-->
              <!--                    </div>-->
              <!--                    <v-card-actions>-->
              <!--                      <v-spacer></v-spacer>-->
              <!--                      <v-btn color="purple darken-1" text @click="openTimelineWarning=false">-->
              <!--                        Cancel-->
              <!--                      </v-btn>-->
              <!--                      <v-btn color="error" @click="ApprovedReplaceTimeline">-->
              <!--                        Ok-->
              <!--                      </v-btn>-->
              <!--                    </v-card-actions>-->
              <!--                  </v-card>-->
              <!--                </v-dialog>-->
              <!--              </v-col>-->

              <!-- Change Collection window hide for saas cycle 1 -->
              <!-- <v-col cols="12" lg="9" v-if="changeCollectionWindow || selectCollectionForDuplicate">
                <v-row>
                  <v-col cols="12">
                    <div style="text-align:left;">
                      <div
                        class="back_btn_class text_color"
                        @click="closeChangeOrDuplicate"
                      >
                        <v-icon class="arrow_icon">chevron_left</v-icon>
                        <span class="mr-1">Back to Options</span>
                      </div>
                    </div>
                    <div class="move-collection-title" v-if="!selectCollectionForDuplicate">
                      <span class=" title-text display-1">Move this style to new collection</span>
                    </div>
                    <div
                      v-if="!changeCollectionWindow"
                      class="duplicate-options-container"
                    >
                      <div class="duplicate-options">
                        <span>Please select an option to continue</span>
                        <v-radio-group v-model="duplicateOption">
                          <v-radio
                            label="Same collection"
                            value="same"
                            @click="inputDuplicateOption()"
                          >
                          </v-radio>
                          <v-radio
                            label="Different collection"
                            value="different"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                    <div v-if="changeCollectionWindow || differentSelect">
                      <div>
                      <md-card-content class="search-content">
                        <v-row class="collection-search-bar">
                          <v-col xl="4" lg="6" xs="6">
                            <v-text-field
                              label="Search Collections"
                              v-on:input="debounceSearch"
                              name="search"
                              id="search"
                              v-model="collectionSearchQuery"
                              clearable
                            />
                          </v-col>
                        </v-row>
                      </md-card-content>
                    </div>
                      <div
                        v-for="collection in collections"
                        v-bind:key="collection.id"
                      >
                        <collection-list
                          :collection="collection"
                          :newStyleButtonShow="false"
                          :changeCollection="true"
                          @change-collection="selectChangeOrDuplicate"
                        />
                      </div>
                      <Pagination
                        style="margin: -9px"
                        :current-page="currentPage"
                        :total-count="totalCount"
                        @load-page="loadPage"
                        v-if="collections.length"
                      />
                      <div v-if="loading" class="my-5">
                        <md-progress-spinner
                          class="color_stroke"
                          md-mode="indeterminate"
                        ></md-progress-spinner>
                      </div>
                      <v-row v-if="!loading && collections.length === 0">
                        <v-col cols="12"> No Collections found. </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-row justify="center" style="background-color: #ffffff">
        <v-col cols="12" lg="9" v-if="isNew || !viewMode">
          <form novalidate class="inquiry-form" @submit.prevent>
            <md-card-content>
              <div class="form-container">
                <md-steppers
                  class="stepper_number_color"
                  :md-active-step.sync="active"
                  md-linear
                  @md-changed="scrollToTop"
                >
                  <md-step
                    id="first"
                    md-label="Product"
                    :md-error="firstStepError"
                    :md-done.sync="first"
                  >
                    <div
                      class="info-container"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                      "
                    >
                      <div
                        class="md-layout-item"
                        style="
                          flex-basis: 50%;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        "
                      >
                        <div class="md-layout-item mb-3" style="width: 100%">
                          <md-field id="product-name" style="width: 100%">
                            <label for="product_name">Style Name</label>
                            <md-input
                              type="text"
                              name="styleName"
                              v-model="model.styleName"
                              maxlength="255"
                            />
                          </md-field>
                        </div>
                        <div
                          v-if="!createNewStyle && !getIsBrand()"
                          class="mb-3"
                          style="width: 100%; margin-top: -20px"
                        >
                          <v-radio-group
                            v-model="model.collectionType"
                            row
                            @change="
                              collectionSearchQuery = null;
                              fetchCollectionList();
                            "
                            :disabled="
                              !count.number_of_collections_owned ||
                              !count.number_of_collections_created_for_others
                            "
                          >
                            <v-radio
                              label="For my collection"
                              value="own"
                            ></v-radio>
                            <v-radio
                              label="For other collection"
                              value="others"
                            ></v-radio>
                          </v-radio-group>
                        </div>

                        <div
                          v-if="!createNewStyle"
                          class="md-layout-item mb-3"
                          style="width: 100%"
                        >
                          <v-autocomplete
                            class="selection-field"
                            clearable
                            :search-input.sync="collectionSearch"
                            v-model="model.collection"
                            :items="collectionList"
                            label="Collection"
                            outlined
                            item-value="id"
                            :filter="() => true"
                            :hide-no-data="collectionLoading"
                            :loading="collectionLoading"
                            :disabled="saving"
                            :error-messages="collectionValidationError"
                            dense
                            style="width: 100%"
                          >
                            <template v-slot:item="{ item }">
                              <span>
                                {{ item.collection_number }} - {{ item.name }}
                              </span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>
                                {{ item.collection_number }} - {{ item.name }}
                              </span>
                            </template>
                            <template v-slot:no-data>
                              <div class="text-center">No data available</div>
                            </template>
                            <template v-slot:append-item>
                              <infinite-loading
                                ref="InfiniteLoading"
                                @infinite="fetchMoreCollections"
                                :key="`infinite-toggle-${collectionSearchQuery}`"
                              >
                                <div
                                  slot="no-more"
                                  v-if="collectionList.length"
                                >
                                  No more data
                                </div>
                                <div
                                  slot="no-results"
                                  v-if="!collectionList.length"
                                >
                                  No more data
                                </div>
                              </infinite-loading>
                            </template>
                          </v-autocomplete>
                        </div>
                        <div class="md-layout-item mb-3" style="width: 100%">
                          <v-select
                            class="selection-field"
                            v-model="model.productType"
                            :items="productTypes"
                            label="Product Type"
                            dense
                            outlined
                            item-value="id"
                            color="#442D65"
                            :error-messages="productTypeValidationError"
                            :disabled="saving"
                          >
                            <template v-slot:item="{ item }">
                              {{ item.name }}
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item.name }}</span>
                            </template>
                          </v-select>
                        </div>
                      </div>
                      <div
                        class="md-layout-item"
                        style="
                          flex-basis: 50%;
                          display: flex;
                          flex-direction: column;
                          align-items: flex-end;
                        "
                      >
                        <div>
                          <div
                            class="lable_size"
                            style="padding-top: 0px; text-align: center"
                          >
                            <label>Style Picture</label>
                          </div>
                          <div
                            class="image-container"
                            style="text-align: center"
                          >
                            <div class="image-child-container">
                              <div>
                                <div
                                  @click="openFileInput()"
                                  class="add-img"
                                  style="cursor: pointer"
                                >
                                  <v-icon class="add-img-icon"
                                    >add_photo_alternate</v-icon
                                  >
                                </div>
                                <input
                                  id="fileInput"
                                  ref="fileInput"
                                  type="file"
                                  accept="image/*"
                                  hidden
                                  @change="uploadThumbnail($event.target.files)"
                                />
                                <img
                                  v-if="selectedThumbnail"
                                  :src="selectedThumbnail"
                                  alt="Selected image"
                                  class="image mt-1"
                                  style="
                                    max-width: 100%;
                                    height: auto;
                                    margin-top: 10px;
                                  "
                                />
                                <embed
                                  v-else
                                  :src="
                                    defaultImage
                                      ? `${baseUrl}/api/inquiries/file_show/${defaultImage}`
                                      : sampleImage
                                  "
                                  alt="sample image"
                                  class="image mt-1"
                                  style="
                                    max-width: 100%;
                                    height: auto;
                                    margin-top: 10px;
                                  "
                                />
                                <div v-if="uploading">
                                  <md-progress-spinner
                                    class="color_stroke"
                                    md-mode="indeterminate"
                                  ></md-progress-spinner>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="md-layout-item"
                      style="width: 70%; margin: 20px auto; text-align: left"
                    >
                      <div
                        style="
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                          margin-top: 20px;
                          text-align: right;
                          width: 100%;
                        "
                      >
                        <div>
                          <div
                            v-if="!isAccountVerified"
                            class="warning-message-container"
                          >
                            <span>
                              Remaining Inquiries count:
                              <b>{{ remainingInquiries }}</b>
                            </span>
                            <span
                              v-if="currentInquiriesCount >= maxAllowed"
                              class="warning-message"
                            >
                              You have reached maximum allowed styles count as
                              an unverified user. Please verify your account to
                              create more styles.
                            </span>
                          </div>
                        </div>
                        <ui-button
                          id="continueButton"
                          class="md-raised"
                          style="align-self: right"
                          @click="
                            if (
                              $v.model.productType.$invalid ||
                              $v.model.collectionType === 'own' ||
                              ($v.model.collectionType === 'others' &&
                                $v.model.collection.$invalid)
                            ) {
                              setError('first');
                              setErrorStep1();
                            } else {
                              setDone('first', 'second');
                            }
                          "
                          title="CONTINUE"
                          :disabled="
                            currentInquiriesCount >= maxAllowed &&
                            !isAccountVerified
                          "
                        />
                      </div>
                    </div>
                  </md-step>

                  <md-step
                    id="second"
                    md-label="Project"
                    :md-error="secondStepError"
                    :md-done.sync="second"
                  >
                    <div class="info-container">
                      <div
                        class="md-layout-item md-small-size-100 container-alignment"
                      >
                        <md-field
                          :class="getValidationClass('quantity')"
                          id="quantity"
                        >
                          <label for="quantity">Bulk Order Quantity</label>
                          <md-input
                            type="number"
                            min="50"
                            max="100000000"
                            name="quantity"
                            v-model="model.quantity"
                            :disabled="saving"
                          />

                          <span
                            class="md-error"
                            v-if="quantityValidationError"
                            >{{ quantityValidationError }}</span
                          >
                        </md-field>
                      </div>
                      <div
                        class="md-layout-item md-small-size-100 container-alignment"
                      >
                        <md-field
                          :class="getValidationClass('noOfColors')"
                          id="noOfColors"
                        >
                          <label for="no_of_colors">Number of Colors</label>
                          <md-input
                            type="number"
                            min="1"
                            max="50"
                            name="noOfColors"
                            v-model="model.noOfColors"
                            :disabled="saving"
                          />

                          <span
                            class="md-error"
                            v-if="noOfColorsValidationError"
                            >{{ noOfColorsValidationError }}</span
                          >
                        </md-field>
                      </div>
                      <div class="md-layout-item md-small-size-100 mt-1">
                        <v-menu
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="model.startDate"
                              label="Start Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="startDateValidationError"
                              dense
                              outlined
                              color="#442D65"
                            />
                          </template>
                          <v-date-picker
                            v-model="model.startDate"
                            @input="startDateMenu = false"
                            :max="model.endDate"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="model.endDate"
                              label="End Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="endDateValidationError"
                              dense
                              outlined
                              color="#442D65"
                            />
                          </template>
                          <v-date-picker
                            v-model="model.endDate"
                            @input="endDateMenu = false"
                            :min="model.startDate"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div class="md-layout-item md-small-size-100">
                        <v-select
                          class="selection-field"
                          v-model="model.currency"
                          :items="currencies"
                          label="Currency"
                          dense
                          outlined
                          item-value="currency_code"
                          color="#442D65"
                          :error-messages="currencyValidationError"
                          :disabled="saving"
                        >
                          <template v-slot:item="{ item }">
                            {{ item.display_name }}
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item.display_name }}</span>
                          </template>
                        </v-select>
                      </div>
                      <div class="md-layout-item md-small-size-100">
                        <v-autocomplete
                          class="selection-field"
                          v-model="model.deliveryDestination"
                          :items="countries"
                          label="Delivery Destination"
                          dense
                          outlined
                          color="#442D65"
                          :error-messages="deliveryDestinationValidationError"
                          :disabled="saving"
                        >
                          <template v-slot:item="{ item }">
                            {{ item }}
                          </template>
                          <template v-slot:selection="{ item }">
                            <span>{{ item }}</span>
                          </template>
                        </v-autocomplete>
                      </div>

                      <md-card-actions style="padding: 8px 0px">
                        <md-button
                          class="md-raised btn-color"
                          @click="
                            if (
                              $v.model.noOfColors.$invalid ||
                              $v.model.quantity.$invalid ||
                              $v.model.currency.$invalid ||
                              $v.model.deliveryDestination.$invalid
                            ) {
                              setError('second');
                              setErrorStep2();
                            } else {
                              setDone('second', 'third');
                            }
                          "
                          >Continue
                        </md-button>
                      </md-card-actions>
                    </div>
                  </md-step>

                  <md-step
                    id="third"
                    md-label="Additional Information"
                    :md-error="thirdStepError"
                    :md-done.sync="third"
                  >
                    <div class="info-container">
                      <div class="md-layout-item md-small-size-100">
                        <div class="attachments-label mb-3">
                          <label for="additional-information">Note:</label>
                        </div>
                        <md-field
                          :class="getValidationClass('additionalInformation')"
                          id="additionalInformation"
                        >
                          <md-textarea
                            name="additionalInformation"
                            id="additional-information"
                            v-model="model.additionalInformation"
                            :disabled="saving"
                          ></md-textarea>
                          <span
                            class="md-error"
                            v-if="additionalInformationValidationError"
                          >
                            {{ additionalInformationValidationError }}
                          </span>
                        </md-field>
                      </div>
                      <div class="md-layout-item md-small-size-100">
                        <div class="attachments-label mb-3">Tech pack:</div>
                        <vue-dropzone
                          class="dropzone mb-3"
                          ref="myVueDropzone"
                          id="dropzone"
                          :options="dropzoneOptions"
                          @vdropzone-file-added="addFiles"
                          @vdropzone-removed-file="removeFiles"
                          @vdropzone-error="collectErrors"
                        ></vue-dropzone>
                        <div
                          v-if="totalFileSizeError"
                          style="color: red; text-align: left"
                        >
                          {{ fileSummationErrorMessage }}
                        </div>
                        <div
                          v-if="fileSizeError"
                          style="color: red; text-align: left"
                        >
                          {{ maxFileSizeErrorMessage }}
                        </div>
                      </div>

                      <md-card-actions style="padding: 8px 0px">
                        <md-button
                          id="saveButton"
                          class="md-raised btn-color"
                          :disabled="saving"
                          @click="
                            if ($v.model.additionalInformation.$invalid) {
                              setError('third');
                              setErrorStep3();
                            } else {
                              save();
                            }
                          "
                        >
                          Save
                        </md-button>
                      </md-card-actions>
                    </div>
                  </md-step>
                </md-steppers>
              </div>
            </md-card-content>
          </form>
        </v-col>
      </v-row>
    </v-container>
    <div v-else class="my-5">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>

    <!-- Approve / Reject dialog hide for saas cycle 1 -->
    <!-- <v-dialog v-model="confirmDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h4">
          Customer requested to delete this Style.
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="handleDeleteRequest(true)">
            Approve
          </v-btn>
          <v-btn
            color="#582963 darken-1"
            text
            @click="handleDeleteRequest(false)"
          >
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import validationMixin from "../../validation/validation_mixin";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import auth from "../../auth";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import Chat from "../../components/Chat";
// import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import * as conf from "../../conf.yml";
// import Timeline from "./Timeline.vue";
import utils from "../../utils";
import SupplierAssignment from "../../views/inquiry/SupplierAssignment.vue";
import NonConsumerTraceForm from "../Inquiry/NonConsumerTraceForm.vue";
// import InquirySideBar from "./InquirySideBar.vue";
import store from "@/store";
import { setLeaveAlert, resetLeaveAlert } from "../../utils/confirmBox";
import StyleFile from "../../views/inquiry/StyleFile.vue";
import Bom from "../../views/inquiry/Bom.vue";
import CostSheet from "../../components/Inquiry/StyleCostSheet.vue";
// import Pagination from "../../components/Pagination";
// import CollectionList from "../collection/CollectionList.vue"
import debounce from "lodash/debounce";
import InfiniteLoading from "vue-infinite-loading";
import CostIcon from "../../assets/icons/CostIcon.vue";
import countryList from "country-list";

const validateQuantity = (value) => {
  if (!value) {
    return false;
  }
  value = parseInt(value);
  if (value >= 1 && value <= 100000000) {
    return true;
  }
  return false;
};
const validateNoOfColors = (value) => {
  if (!value) {
    return false;
  }
  value = parseInt(value);
  if (value >= 1 && value <= 50) {
    return true;
  }
  return false;
};
const validateAdditionalInformation = (value) => {
  if (!value) {
    return true;
  }
  if (value.length <= 5000) {
    return true;
  }
  return false;
};
const validateServices = (value) => {
  if (typeof value == "object" && value.length >= 1) {
    return true;
  }
  return false;
};

export default {
  name: "Inquiry",
  mixins: [validationMixin],
  components: {
    // Timeline,
    // Chat,
    // InquirySideBar,
    // SupplierAssignment,
    NonConsumerTraceForm,
    SupplierAssignment,
    StyleFile,
    Bom,
    CostSheet,
    vueDropzone: vue2Dropzone,
    InfiniteLoading,
    CostIcon,
    // Pagination,
    // CollectionList,
  },
  data: () => ({
    sampleImage: require(`../../assets/shirt.png`),
    selectedThumbnail: null,
    selectedFile: null,
    uploading: false,
    openTimelineWarning: false,
    timelineStyleNumber: null,
    styleNumbers: null,
    activeTab: "project",
    updateStatusOpen: false,
    bookMeeting: false,
    userId: null,
    inquiryId: null,
    isAvailable: false,
    viewMode: true,
    editMode: false,
    importTimeline: false,
    assignMode: false,
    isNew: false,
    today: null,
    chatStatus: false,
    newTimeline: true,
    active: "first",
    first: false,
    second: false,
    third: false,
    alert: false,
    secondStepError: null,
    firstStepError: null,
    thirdStepError: null,
    techPackDevelopment: false,
    chatID: null,
    startingRoomId: null,
    uploadFiles: [],
    unsupportedFiles: [],
    dropzoneOptions: {
      url: "https://",
      autoProcessQueue: false,
      thumbnailWidth: 200, // px
      thumbnailHeight: 200,
      maxFilesize: 50,
      language: {
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",
      },
      acceptedFiles:
        ".pdf,.jpg,.jpeg,.png,.svg,.gif,.ai,.eps,.ait,.svgz,.bmp,.css,.dwg,.dxf,.emf,.swf,.pct,.psd,.tif,.tga,.txt,.wmf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xlw,.xlr,.xla,.mp4,.mp3,.tuka,.gerber,.tiff,.ng,.pxr,.dcm,.pbm,.jps,.dng,.raw,.ct,.tga,.ait,.epsf,.ps,.doc,.docm,.docx,.dot,.dotx,.pptx,.pptm,.ppt,.potx,.potm,.pot,.ppsx,.pps,.ppsm,.cdr,.wav,.mov,.dmg,.aae,.heic,.dotm,.dotx,.dot,.htm,.html,.mht,.mhtml,.odt,.rtf,.wps,.dic,.csv,.dbf,.dif,.ods,.prn,.slk,.xps,.ppa,.ppam,.thmx,.wmv,.abr,.csh,.psb,.u3d,.8ba,.8bam,.8bbr,.8bc,.8bco,.8bct,.8bdt,.8be,.8bem,.8bf,.8bfk,.8bfm,.8bi,.8bi8,.8bim,.8bl,.8bp,.8bpf,.8bpi,.8bs,.8bx,.8bxm,.8bym,.8li,.8ly,.acb,.acf,.acm,.aco,.act,.acv,.ado,.ahs,.ahu,.ai3,.ai8,.alv,.amp,.ams,.asl,.asp,.asr,.ast,.asv,.atf,.atn,.ava,.avs,.axt,.blw,.cha,.cpsd,.csf,.dcm,.dcs2,.dic,.drx,.eap,.edb,.enf,.eps2,.eps3,.ensi,.ensp,.erf,.ffo,.fl3,.gbt,.grd,.hdr,.hdt,.hss,.iros,.irs,.isa,.inf,.imbs,.mag,.mdo,.mos,.mpo,.mtl,.p3e,.p3l,.p3m,.p3r,.pam,.pat,.pdd,.pf,.pipl,.pipeg,.pmb,.pmg,.prst,.psdt,.psf,.pxr,.g2g,.g5r,.rfr,.shc,.ssh,.spiff,.sta,.swb,.tbo,.thr,.tpl,.ype,._v,.3ds,.arw,.cr2,.crw,.csh,.dcr,.dib,.emz,.ico,.kmz,.mrw,.nef,.obj,,orf,,pcd,,plugin,.psdx,.raf,.syg,.wdp,.webp,.acbl,.afx,.ai4,.ai5,.ai6,.ai7,.ase,.asef,.bm2,.cal,.clp,.colz,.dae,.dc2,.dcp,.dcpr,.dcs,.dicm,.dxt5,.exif,.exr,.fff,.fif,.gif~c200,.hdr,.icb,.icc,.icm,.ifi,.iif,.jp2,.jp2,.jpg-large,.jpg-original,.ipx,.ixr,.kdc,.kdk,.kodak,.kys,.lcpp,.lex,.mnu,.mxp,.nrw,.pat,.pbm,.pcq,.pdp,.pef,.png-large,.pnm,.pop,.psid,.q7q,.rwl,.sct,.sdpx,.spf,.srw,.stn=,.tdi,.vph,.wbm,.x3f,.zdct,.aac,.aiff,.m2a,.m4a,.mp2,.h264,.3gp,.3gpp,.avc,.mpeg-2,.mpeg-4,.mts,.mxt,.r3d,.ts,.vob,.dpx,.mov,.jpeg2000,.mpo,.kmz,.jps,.dae,.pcx,.taaga,.svq,.svqz,.aia,.aip,.ait,.aia,.aip,.ait,.cef,.cqm,.clx,.epf,.epsp,.fit,.fmod,.iros,.irs,.kys,.psid,.pwl,.sbx,.pic,.kys,.psid,.pwl,.sbx,.art,.env,.fh10,.fh11,.fh7,.fh8,.fh9,.fh8,.fxq,.idea,.j2k,.jp2,.jpf,.jpx,.lex,.pxr,.vda,.vst,.zdct",

      addRemoveLinks: true,
    },
    confirmDialog: false,
    showFabricForm: false,
    showTechPack: false,
    TechPackCompleted: false,
    TechPackStarted: false,
    techPackRoles: [],
    fileSummationErrorMessage:
      "Selected files are exceeding the maximum file summation limit (100mb)",
    maxFileSizeErrorMessage: "One or more files are too large to upload (50mb)",
    totalFileSizeError: false,
    fileSizeError: false,
    totalFileSize: 102000000,
    maximumFileSize: 52000000,
    FabricStarted: false,
    ownCollections: [],
    otherCollections: [],
    FabrickNotStarted: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    traceForm: true,
    tracePreview: false,
    startDateValidationError: null,
    endDateValidationError: null,
    model: {
      id: null,
      collectionType: "own",
      styleNumber: null,
      styleName: null,
      productType: null,
      startDate: "",
      endDate: "",
      deliveryDestination: null,
      quantity: null,
      inquiryQty: null,
      noOfColors: null,
      material: null,
      currency: "USD",
      additionalInformation: null,
      isSuperAdmin: false,
      files: [],
      collection: null,
      inquiryStatus: "active",
      createdAt: null,
      updatedAt: null,
      accountManagers: [],
      superAdmin: null,
      collectionId: null,
    },
    collectionDetails: null,
    assignedSuppliers: [],
    assignedValueChain: [],
    productTypes: [],
    defaultImage: "",
    materials: [],
    currencies: [],
    services: [],
    categories: [],
    loading: false,
    saving: false,
    baseUrl: "",
    count: {
      number_of_collections_owned: 0,
      number_of_collections_created_for_others: 0,
    },
    collectionList: [],
    showMore: false,
    acceptedFileTypes: utils.acceptedFileTypes,
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    routeLeaveAlert: false,
    exceededDialog: false,
    subceededDialog: false,
    showOptions: true,
    ignoreFormEdit: false,
    pinnedMessages: [],
    notFound: false,
    changeCollectionWindow: false,
    collections: [],
    currentPage: 1,
    totalCount: 0,
    createNewStyle: false,
    collectionLoading: false,
    collectionPage: 1,
    selectCollectionForDuplicate: false,
    duplicateOption: null,
    traceLayout: null,
    collectionSearchQuery: "",
    currentInquiriesCount: null,
    maxAllowed: 10,
  }),
  validations: {
    model: {
      collection: {
        required: requiredIf(function () {
          return !this.createNewStyle;
        }),
      },
      productType: {
        required,
      },
      deliveryDestination: { required },
      quantity: {
        required,
        validateQuantity,
      },
      noOfColors: {
        required,
        validateNoOfColors,
      },
      currency: {
        required,
      },
      additionalInformation: {
        validateAdditionalInformation,
      },
    },
  },

  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus();
      },
    },
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },

  watch: {
    model: {
      handler: function () {
        this.ignoreFormEdit = setLeaveAlert(
          this.ignoreFormEdit,
          !this.$store.state.inquiryViewMode || this.inquiryId == "new"
        );
      },
      deep: true,
    },
    isAllowedToChangeInquiry: {
      handler: function (value) {
        if (!this.isVerified) {
          this.activeTab = "suppliers";
          window.history.pushState({}, "", window.location.href.split("?")[0]);
          return;
        }

        if (this.$route.query.tab == "style_file") {
          this.activeTab = "styleFile";
        }

        if (this.$route.query.tab == "bom") {
          this.activeTab = "bom";
        }

        if (this.$route.query.tab == "cost_sheet") {
          this.activeTab = "costSheet";
        }

        if (value) {
          if (this.$route.query.tab == "trace") {
            this.activeTab = "trace";
          }
        }

        window.history.pushState({}, "", window.location.href.split("?")[0]);
      },
      immediate: true,
    },
  },

  computed: {
    collectionSearch: {
      get() {
        return this.collectionSearchQuery;
      },
      set(searchInput) {
        if (this.collectionSearchQuery !== searchInput) {
          this.collectionSearchQuery = searchInput;
          this.collectionLoading = true;
          this.debounceSearch();
        }
      },
    },
    countries() {
      return countryList.getNames();
    },
    isAllowedToChangeInquiry() {
      return (
        auth.user.account.organization_registry_id ===
          this.collectionDetails?.brand_id ||
        auth.user.account.organization_registry_id ===
          this.collectionDetails?.created_by_organization_id
      );
    },
    isVerified() {
      return auth.user.account.verified === 1;
    },
    isActive() {
      return this.model.inquiryStatus === "active";
    },
    collectionItems() {
      if (this.model.collectionType === "own") {
        return this.ownCollections;
      } else if (this.model.collectionType === "others") {
        return this.otherCollections;
      }
      return [];
    },
    differentSelect() {
      if (
        this.selectCollectionForDuplicate &&
        this.duplicateOption == "different"
      ) {
        return true;
      }
      return false;
    },

    collectionValidationError() {
      if (!this.model.collection && this.$v.model.collection.$dirty) {
        return "The collection is required";
      }
      return null;
    },
    showMoreText() {
      if (
        this.showMore ||
        (this.model.additionalInformation &&
          this.model.additionalInformation.length <= 50)
      ) {
        return this.model.additionalInformation;
      } else {
        return (
          this.model.additionalInformation &&
          this.model.additionalInformation.slice(0, 50) + "..."
        );
      }
    },
    productTypeValidationError() {
      if (!this.model.productType && this.$v.model.productType.$dirty) {
        return "The product type is required";
      }
      return null;
    },
    categoryValidationError() {
      if (!this.model.category && this.$v.model.category.$dirty) {
        return "The category is required";
      }
      return null;
    },
    quantityValidationError() {
      if (!this.$v.model.quantity.required) {
        return "The quantity is required";
      }
      if (!this.$v.model.quantity.validateQuantity) {
        return "The quantity should be between 1 and 100,000,000";
      }
      return null;
    },
    noOfColorsValidationError() {
      if (!this.$v.model.noOfColors.required) {
        return "The number of colours is required";
      }
      if (!this.$v.model.noOfColors.validateNoOfColors) {
        return "The number of colours should be between 1 and 50";
      }
      return null;
    },
    startDateValidationError() {
      if (!this.model.startDate && this.$v.model.startDate.$dirty) {
        return "The start date is required";
      }
      return null;
    },

    endDateValidationError() {
      if (!this.model.endDate && this.$v.model.endDate.$dirty) {
        return "The end date is required";
      }
      if (
        this.model.endDate &&
        this.model.startDate &&
        new Date(this.model.endDate) < new Date(this.model.startDate)
      ) {
        return "End date must be after the start date.";
      }
      return null;
    },
    currencyValidationError() {
      if (!this.model.currency && this.$v.model.currency.$dirty) {
        return "The currency is required";
      }
      return null;
    },
    deliveryDestinationValidationError() {
      if (
        !this.model.deliveryDestination &&
        this.$v.model.deliveryDestination.$dirty
      ) {
        return "The delivery destination is required";
      }
      return null;
    },
    additionalInformationValidationError() {
      if (
        !this.$v.model.additionalInformation.validateAdditionalInformation &&
        this.$v.model.additionalInformation.$dirty
      ) {
        return "The additional information should be less than 5000 characters";
      }
      return null;
    },
    inquiryViewWidth() {
      if (this.chatStatus) {
        return 3;
      }
      return 12;
    },
    isAccountVerified() {
      return auth.user.account?.verified;
    },
    remainingInquiries() {
      if (this.currentInquiriesCount === null) {
        return null;
      } else {
        return Math.max(this.maxAllowed - this.currentInquiriesCount, 0);
      }
    },
  },

  mounted() {
    this.scrollToTop();
    this.getCount();
    this.chatID = this.startingRoomId;
    this.userId = auth.user.id;
    this.loading = true;
    this.baseUrl = conf.default.server.host;
    this.inquiryId = this.$route.params.id;
    if (!this.inquiryId || this.inquiryId == "new") {
      if (this.getIsBrand()) {
        this.fetchCollectionList();
      }
      this.isNew = true;
      this.loading = false;
      this.notFound = false;
      this.ignoreFormEdit = true;
      if (this.$route.query.collectionId) {
        this.createNewStyle = true;
        this.model.collection = parseInt(this.$route.query.collectionId);
      }
    } else {
      this.getInquiry(this.inquiryId)
        .then((response) => {
          const inquiry = response.data.inquiry;
          this.defaultImage = response.data.default_image;
          this.model.collectionId = inquiry.collection_id;
          this.model.id = inquiry.id;
          this.model.collectionId = inquiry.collection_id;
          this.model.styleNumber = inquiry.style_number;
          this.model.styleName = inquiry.style_name;
          this.model.startDate = inquiry.start_date;
          this.model.endDate = inquiry.end_date;
          this.model.deliveryDestination = inquiry.delivery_destination;
          this.model.productType = parseInt(inquiry.product_type);
          this.model.category = parseInt(inquiry.categories);
          this.model.quantity = inquiry.bulk_order_quantity;
          this.model.inquiryQty = inquiry.bulk_order_quantity;
          this.model.noOfColors = inquiry.colour_number;
          this.model.material = parseInt(inquiry.materials);
          this.model.currency = inquiry.currency;
          this.model.additionalInformation = inquiry.additional_information;
          this.model.files = response.data.files;
          this.model.collection = inquiry.collection_id;
          this.model.createdAt = inquiry.created_at;
          this.model.updatedAt = inquiry.updated_at;
          this.model.inquiryStatus = inquiry.status;
          this.collectionDetails = inquiry.collection;

          store.commit("SET_INQUIRY_VERIFICATION_STATUS", {
            hasPermissions:
              auth.user.account.organization_registry_id ===
                this.collectionDetails.brand_id ||
              auth.user.account.organization_registry_id ===
                this.collectionDetails.created_by_organization_id,
            verified: inquiry.verified,
            requested: inquiry.verification_requested,
            requestable: inquiry.verification_requestable,
          });

          this.ignoreFormEdit = true;

          this.assignedSuppliers = [];
          response.data.suppliers &&
            response.data.suppliers.forEach((supplier) => {
              supplier.id = supplier.supplier_id;
              this.assignedSuppliers.push(supplier);
            });
          if (!this.$route.query.tab) {
            if (this.assignedSuppliers.length && auth.user.account.verified) {
              this.activeTab = "bom";
            } else {
              this.activeTab = "suppliers";
            }
          }
          this.assignedValueChain =
            inquiry?.inquiry_value_chain?.account_value_chain || null;
          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          this.notfound = true;
          notification.error("Style not found");
          this.$router.push({ name: "All Collections" });
        });
      this.getTraceStatus();
    }
    this.today = this.$moment(new Date()).format("YYYY-MM-DD");
    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });
    this.getCurrencies().then((response) => {
      this.currencies = response.data.currencies;
    });
    // this.getServices()
    //   .then((response) => {
    //     this.services = response.data.services;
    //     return this.services;
    //   })
    //   .then((services) => {
    //     services.forEach((service) => {
    //       if (this.model.serviceDeadlines[service.id] === undefined) {
    //         this.model.serviceDeadlines[service.id] = this.today;
    //       }
    //     });
    //     this.ignoreFormEdit = true;
    //   });
    // this.checkFabricFormSubmitted();
    // this.checkFabricStarted();
    // this.checkTechPackStarted();
    // this.checkTechPackSubmitted();
    this.getCategories().then((response) => {
      this.categories = response.data.categories;
    });
    // this.getTechPackRoles();
    store.commit("SET_INQUIRY_VIEW_MODE", true);

    this.getInquiriesCountByOrganization();
  },

  beforeRouteUpdate(to, from, next) {
    if (to && to.params && to.params.id == "new") {
      this.isNew = true;
      this.clearForm();
    }
    next();
  },
  methods: {
    copyStyleFileLink() {
      navigator.clipboard.writeText(
        `${window.location.origin}/#/style_file/${this.model.styleNumber}`
      );
      notification.success("Link copied to clipboard");
    },
    copyBomLink() {
      navigator.clipboard.writeText(
        `${window.location.origin}/#/bom/${this.model.styleNumber}`
      );
      notification.success("Link copied to clipboard");
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleCollectionTypeChange() {
      this.fetchCollectionList();
    },
    traceRejected(value) {
      this.isAvailable = value;
    },
    layoutRequested(requestedLayout) {
      this.traceLayout = requestedLayout;
    },
    addFiles: function (file) {
      if (file.size > this.maximumFileSize) {
        this.fileSizeError = true;
      }
      this.uploadFiles.push({ file });

      let fileSize = 0;

      for (let index = 0; index < this.uploadFiles.length; index++) {
        fileSize = fileSize + this.uploadFiles[index].file.size;
      }

      this.totalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },
    removeFiles(file) {
      let fileSize = 0;

      for (let index = 0; index < this.uploadFiles.length; index++) {
        if (this.uploadFiles[index].file.name === file.name) {
          this.uploadFiles.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.uploadFiles[index] ? this.uploadFiles[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.totalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.fileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },
    collectErrors(file, message, xhr) {
      this.unsupportedFiles.push({ file });
    },
    saveAndBookMeeting() {
      this.bookMeeting = true;
      this.save();
    },
    saveAndTechPackDevelopment() {
      this.$router.push({
        path: `${this.inquiryId}/tech_pack_development`,
      });
    },
    openFabricSourcingForm() {
      this.$router.push({
        path: `${this.inquiryId}/fabric_sourcing_form`,
      });
    },
    openBomSheet() {
      this.$router.push({
        path: `${this.inquiryId}/bom`,
      });
    },
    openStyleCostSheet() {
      this.$router.push({
        path: `${this.inquiryId}/style_costing_sheet`,
      });
    },

    setDone(id, index) {
      this[id] = true;

      this.secondStepError = null;
      this.firstStepError = null;
      this.thirdStepError = null;

      if (index) {
        this.active = index;
      }
    },
    setError(step) {
      if (step === "second") {
        this.secondStepError = "Fill all the fields!";
      } else if (step === "first") {
        this.firstStepError = "Fill all the fields";
      } else if (step === "third") {
        this.thirdStepError = "Fix errors to continue";
      }
    },
    setErrorStep1() {
      this.$v.model.productType.$touch();
      this.$v.model.collection.$touch();
      if (
        this.$v.model.productType.$invalid ||
        this.$v.model.collection.$invalid
      ) {
        return false;
      } else {
        return true;
      }
    },
    setErrorStep2() {
      this.$v.model.noOfColors.$touch();
      this.$v.model.quantity.$touch();
      this.$v.model.currency.$touch();
      this.$v.model.deliveryDestination.$touch();

      if (
        this.$v.model.noOfColors.$invalid ||
        this.$v.model.quantity.$invalid ||
        this.$v.model.currency.$invalid ||
        this.$v.model.deliveryDestination.$invalid
      ) {
        return false;
      } else {
        return true;
      }
    },
    setErrorStep3() {
      this.$v.model.additionalInformation.$touch();
      if (this.$v.model.additionalInformation.$invalid) {
        return false;
      } else {
        return true;
      }
    },

    fetchCollectionList() {
      let collectionType;
      this.collectionPage = 1;

      if (this.getIsBrand()) {
        collectionType = "all";
      } else {
        collectionType =
          this.model.collectionType === "own" ? "own" : "created_for_others";
        if (!collectionType) {
          collectionType = "all";
        }
      }

      const apiUrl = `/api/collections?page=1&collection_type=${collectionType}${
        this.collectionSearchQuery ? "&query=" + this.collectionSearchQuery : ""
      }`;

      restAdapter
        .get(apiUrl)
        .then((response) => {
          if (!response.data.data?.length) {
            this.collectionList = [];
          } else {
            this.collectionList = response.data.data;
          }
          this.collectionLoading = false;
        })
        .catch((error) => {
          notification.error("Failed to fetch collections. Please try again.");
        });
    },

    fetchMoreCollections($state) {
      let collectionType;
      this.collectionPage++;

      if (this.getIsBrand()) {
        collectionType = "all";
      } else {
        collectionType =
          this.model.collectionType === "own" ? "own" : "created_for_others";
        if (!collectionType) {
          collectionType = "all";
        }
      }

      const apiUrl = `/api/collections?page=${this.collectionPage}&collection_type=${collectionType}`;

      restAdapter
        .get(apiUrl)
        .then((response) => {
          if (!response.data.data.length) {
            $state.complete();
          } else {
            this.collectionList = this.collectionList.concat(
              response.data.data
            );
            $state.loaded();
          }
        })
        .catch((error) => {
          notification.error("Failed to fetch collections. Please try again.");
        });
    },

    refreshFiles() {
      this.getInquiry(this.inquiryId).then((response) => {
        this.model.files = response.data.files;
        this.ignoreFormEdit = true;
      });
    },
    qtyUpdatedWarning() {
      if (this.model.quantity < this.model.inquiryQty) {
        return 1;
      } else if (this.model.quantity > this.model.inquiryQty) {
        return 2;
      }
      return null;
    },

    openTraceView() {
      this.getTraceStatus();
      if (this.traceForm) {
        this.traceForm = false;
        this.tracePreview = true;
      } else {
        this.traceForm = true;
        this.tracePreview = false;
      }
    },

    async getInquiriesCountByOrganization() {
      try {
        const { data } = await restAdapter.get(
          `/api/inquiries/count/${auth.user.account.organization_registry_id}`
        );
        this.currentInquiriesCount = data?.count || 0;
      } catch (error) {
        console.error("Inquiries count fetch failed:", error);
        this.currentInquiriesCount = 0;
      }
    },

    processContinue() {
      const error = this.qtyUpdatedWarning();
      if (error == 1) {
        this.subceededDialog = true;
        return;
      }
      if (error == 2) {
        this.exceededDialog = true;
        return;
      }
      if (!error) {
        this.setDone("second", "third");
      }
    },

    agreeStatus() {
      this.model.inquiryQty = this.model.quantity;
      this.exceededDialog = false;
      this.subceededDialog = false;
    },

    resetQuantity() {
      this.exceededDialog = false;
      this.subceededDialog = false;
      this.model.quantity = this.model.inquiryQty;
    },
    deleteFile(id) {
      if (confirm("Delete this file?")) {
        restAdapter.delete("/api/inquiries/file_delete/" + id).then(() => {
          notification.success("File deleted successfully");
          this.refreshFiles();
        });
      }
    },
    deleteInquiry(id) {
      const answer = prompt(
        "Delete this Style? Type 'delete' and choose OK to delete"
      );
      if (answer === "delete") {
        restAdapter.delete("/api/inquiries/" + id).then(() => {
          notification.success("Style deleted successfully");
          this.$router.push({ name: "All Collections" });
        });
      } else if (answer !== null) {
        notification.error(
          "Please type the exact word 'delete'. Style was not deleted."
        );
      }
    },
    handleDeleteRequest(isApproved) {
      restAdapter
        .post("/api/inquiries/" + this.model.id + "/approve_deletion", {
          approve_status: isApproved,
        })
        .then(() => {
          if (isApproved) {
            notification.success("Style deleted successfully");
          } else {
            notification.success("Delete Request Rejected");
          }
          this.$router.push({ name: "All Collections" });
        });
      this.confirmDialog = false;
    },
    duplicateInquiry(collectionId) {
      if (confirm("Duplicate this Style?")) {
        this.closeCollectionForDuplicate();
        restAdapter
          .post("/api/inquiries/" + this.model.id + "/duplicate", {
            collection_id: collectionId,
          })
          .then(() => {
            notification.success("Style duplicated successfully");
          });
      } else {
        if (!this.differentSelect) {
          this.duplicateOption = null;
        }
      }
    },
    openCollectionForDuplicate() {
      this.showOptions = false;
      // will uncomment once we implement duplicate collection feature
      // this.getCollections();
      this.selectCollectionForDuplicate = true;
    },
    updateInquiryStatus(id, status) {
      this.closeStatusWindow();
      this.model.inquiryStatus = status;
      this.ignoreFormEdit = true;
      store.commit("SET_INQUIRY_STATUS", this.model.inquiryStatus);
      const updatedStatus = {
        status: this.model.inquiryStatus,
      };
      restAdapter.put("/api/inquiries/status/" + id, updatedStatus).then(() => {
        notification.success(
          "Style status updated to " + this.model.inquiryStatus
        );
      });
    },
    inputDuplicateOption() {
      if (this.duplicateOption && !this.differentSelect) {
        setTimeout(() => {
          this.duplicateInquiry(this.model.collectionId);
        }, 50);
      }
    },
    getIsAccountManager() {
      return (
        auth.user.account.organization_registry["type"] ===
          "supplier_account_manager" ||
        auth.user.role["name"] === "brand_account_manager"
      );
    },
    isSuperAdmin() {
      return this.model.isSuperAdmin;
    },
    getIsSupplier() {
      return (
        auth.user.account?.organization_registry.type_details.short_code ===
        "supplier"
      );
    },
    getIsBrand() {
      return (
        auth.user.account?.organization_registry.type_details.short_code ===
        "brand"
      );
    },
    getIsNotAssignedToManager() {
      return !this.model.accountManagers.find(
        (manager) => manager.id === auth.user.id
      );
    },
    getTechPackRoles() {
      restAdapter.get("/api/tech_pack_roles/").then((response) => {
        this.techPackRoles = response.data.tech_pack_roles;
      });
    },
    isOwner() {
      return this.model.createdBy === auth.user.id;
    },
    isAssignedAccountManager() {
      return this.model.accountManagers.find(
        (manager) => manager.id === auth.user.id
      );
    },
    isAssignedSupplier() {
      return this.assignedSuppliers
        .map((assignedSupplier) => assignedSupplier.id)
        .includes(auth.user.id);
    },
    isAccountManagerAssigned() {
      if (!this.model.accountManagers.length) {
        return false;
      }

      return true;
    },
    getStatus() {
      return this.model.inquiryStatus;
    },
    isAssignedToTechPack() {
      return this.techPackRoles.some(
        (role) => auth.user.subRoles.indexOf(role) >= 0
      );
    },
    checkFabricFormSubmitted() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/fabric_form`)
        .then((response) => {
          if (response.data.fabricData.fabricSourcing) {
            if (response.data.fabricData.fabricSourcing.is_submitted === 1) {
              this.showFabricForm = true;
            }
          }
        });
    },
    checkFabricStarted() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/fabric_form`)
        .then((response) => {
          const fabricStatus = response.data.fabric_data;
          if (fabricStatus) {
            this.showFabricForm = false;
            this.FabricStarted = true;
            this.FabrickNotStarted = false;
          }
        });
    },
    fileDownload(file) {
      window.open(
        `${conf.server.host}/api/inquiries/file_download/${file.upload_name}`
      );
      // const imageUrl = file.file_url;
      // const downloadImage = document.createElement("a");
      // downloadImage.href = imageUrl;
      // downloadImage.download = file.upload_name;
      // downloadImage.click();
    },

    openChangeCollection() {
      // will uncomment once we implement change collection feature
      // this.getCollections();
      this.changeCollectionWindow = true;
      this.showOptions = false;
    },

    closeChangeCollection() {
      this.changeCollectionWindow = false;
      this.showOptions = true;
    },

    closeCollectionForDuplicate() {
      this.duplicateOption = null;
      this.selectCollectionForDuplicate = false;
      this.showOptions = true;
      this.duplicateOption = null;
    },
    closeChangeOrDuplicate() {
      if (this.changeCollectionWindow) {
        this.closeChangeCollection();
      } else {
        this.closeCollectionForDuplicate();
      }
    },
    selectChangeOrDuplicate(collectionId) {
      if (this.changeCollectionWindow) {
        this.changeCollection(collectionId);
      } else {
        this.duplicateInquiry(collectionId);
      }
    },
    changeCollection(id) {
      if (confirm("Do you want to move this style to collection?")) {
        restAdapter
          .post(`/api/collections/${id}/add/${this.inquiryId}`)
          .then((response) => {
            notification.success("Change Collection Successfully");
            this.changeCollectionWindow = false;
            this.showOptions = true;
            this.model.collectionId = id;
            this.model.collection = id;
            store.commit("SET_UPDATE_INQUIRY_IN_APP_BAR", true);
          })
          .catch((error) => {
            notification.error("Something went wrong");
          });
      }
    },

    debounceSearch: debounce(function () {
      this.fetchCollectionList();
    }, 600),

    clearForm() {
      this.$v.$reset();
      this.model.id = null;
      this.model.styleNumber = null;
      this.model.productType = null;
      this.model.deliveryDestination = null;
      this.model.quantity = null;
      this.model.noOfColors = null;
      this.model.startDate = null;
      this.model.endDate = null;
      // this.model.selectedServices = [];
      // this.model.serviceDeadlines = {};
      this.model.currency = null;
      this.model.additionalInformation = null;
      this.model.files = null;
      this.ignoreFormEdit = true;
    },
    clearFileForm() {
      this.$refs.file.value = null;
    },
    getInquiry(id) {
      return restAdapter.get("/api/inquiries/" + id);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getCurrencies() {
      return restAdapter.get("/api/inquire_currencies");
    },
    // getServices() {
    //   return restAdapter.get("/api/inquire_services");
    // },
    getCategories() {
      return restAdapter.get("/api/inquire_categories");
    },
    checkTechPackStarted() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/tech_pack`)
        .then((response) => {
          const techPack = response.data.techPack;
          if (techPack) {
            this.showTechPack = true;
            this.TechPackStarted = true;
          }
        });
    },
    getCount() {
      if (this.getIsBrand()) return;
      restAdapter.get(`/api/dashboard_details`).then((response) => {
        store.commit("SET_COLLECTION_COUNTS", response.data);
        this.count = response.data;

        if (!this.count.number_of_collections_owned) {
          this.model.collectionType = "others";
          this.fetchCollectionList();
        } else {
          this.model.collectionType = "own";
          this.fetchCollectionList();
        }
      });
    },
    checkTechPackSubmitted() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/tech_pack`)
        .then((response) => {
          if (
            response.data.techPack &&
            response.data.techPack.is_submitted === 1
          ) {
            this.TechPackStarted = false;
            this.TechPackCompleted = true;
          }
        });
    },
    getStyleNumber() {
      restAdapter
        .get(`/api/inquiries/${this.inquiryId}/other_inquiry_style_numbers`)
        .then((response) => {
          this.styleNumbers = response.data.data;
        })
        .catch((error) => {
          notification.error("Something went wrong.");
        });
    },

    ApprovedReplaceTimeline() {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/import_timeline`, {
          id: this.timelineStyleNumber,
        })
        .then((response) => {
          notification.success("Timeline imported successfully.");
        })
        .catch((error) => {
          notification.error("Something went wrong.");
        });
      this.openTimelineWarning = false;
    },
    openFileInput() {
      document.getElementById("fileInput").click();
    },
    uploadThumbnail(files) {
      if (files.length !== 0) {
        const file = files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          this.selectedThumbnail = e.target.result;
          this.selectedFile = file;
        };
        reader.readAsDataURL(file);
      }
    },
    saveThumbnail() {
      if (this.selectedFile) {
        this.uploading = true;
        const formData = new FormData();
        formData.append("file[]", this.selectedFile);
        restAdapter
          .post("/api/inquiries/files_upload/" + this.inquiryId, formData)
          .then((response) => {
            this.setDefaultImage(response.data.attachment_id);
          })
          .catch((error) => {
            console.error(error);
            notification.error("Something went wrong.");
            this.uploading = false;
          });
      }
    },
    setDefaultImage(id) {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_default_image/`, {
          default_image: id,
        })
        .then((response) => {
          notification.success("Image set as default thumbnail successfully");
          this.defaultImage = response?.data?.inquiry?.default_image;
          this.uploading = false;
        })
        .catch((error) => {
          console.error(error);
          notification.error("Failed to set default image.");
          this.uploading = false;
        });
    },
    save() {
      this.$v.$touch();
      this.$nextTick(() => {
        if (document.querySelector(".md-invalid")) {
          const domRect = document
            .querySelector(".md-invalid")
            .getBoundingClientRect();
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      });
      if (this.$v.$invalid) {
        return;
      }
      if (this.unsupportedFiles.length > 0) {
        this.alert = true;
        return;
      }
      this.saving = true;
      // this.model.selectedServices.forEach((id) => {
      //   services.push([id, this.model.serviceDeadlines[id]]);
      // });
      // this.model.selectedServices.sort().forEach((id) => {
      //   services.push(id);
      // });
      const data = {
        style_name: this.model.styleName,
        product_type: this.model.productType,
        start_date: this.model.startDate,
        end_date: this.model.endDate,
        delivery_destination: this.model.deliveryDestination,
        bulk_order_quantity: this.model.quantity,
        colour_number: this.model.noOfColors,
        currency: this.model.currency,
        additional_information: this.model.additionalInformation,
        collection_id: this.model.collection,
      };
      this.alertBoxSet(false, true);
      if (this.isNew) {
        restAdapter
          .post("/api/inquiry", data)
          .then((response) => {
            this.inquiryId = response.data.data.id;
            this.styleFileId = response.data.tech_pack_id;
            notification.success("You Have Created Style Successfully");
            this.saveThumbnail();
            if (this.uploadFiles.length !== 0) {
              const formData = new FormData();
              for (let i = 0; i < this.uploadFiles.length; i++) {
                const file = this.uploadFiles[i].file;
                formData.append("file[" + i + "]", file);
              }
              restAdapter
                .post(
                  `/api/inquiries/${this.inquiryId}/style_files/${this.styleFileId}/style_file_upload/`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then(() => {
                  notification.success("File uploaded successfully");
                })
                .catch((error) => {
                  notification.error(error.response.data.status);
                });
            }
            this.clearForm();
            resetLeaveAlert();
            if (this.bookMeeting) {
              this.$router.push({ name: "Book a Meeting" });
            } else {
              this.$router.push({
                name: "Inquiry",
                params: { id: response.data.data.id },
              });
            }
          })
          .catch((error) => {
            this.saving = false;
            this.alertBoxSet(true, false);
            if (
              error.response &&
              error.response.status == 400 &&
              error.response.data &&
              error.response.data.error
            ) {
              notification.errors(error.response.data.error);
            } else {
              notification.error(
                "Something went wrong, please try again later."
              );
            }
          });
      } else {
        restAdapter
          .put("/api/inquiries/" + this.model.id, data)
          .then((response) => {
            this.saving = false;
            (this.active = "first"), this.switchEditMode();
            notification.success("You Have Updated Style Successfully");
            this.saveThumbnail();
            store.commit("SET_UPDATE_STYLE_IN_APP_BAR", {
              styleNumber: this.model.styleNumber,
              styleName: this.model.styleName,
            });
          })
          .catch((error) => {
            this.saving = false;
            this.alertBoxSet(true, false);
            if (
              error.response &&
              error.response.status == 400 &&
              error.response.data &&
              error.response.data.error
            ) {
              notification.errors(error.response.data.error);
            } else {
              notification.error(
                "Something went wrong, please try again later."
              );
            }
          });
      }
    },
    // isSelectedService(id) {
    //   return this.model.selectedServices.indexOf(id) !== -1;
    // },
    getProductTypeName(id) {
      const productType = this.productTypes.find(
        (productType) => productType.id == id
      );
      return productType && productType.name;
    },
    getCategoryName(id) {
      const category = this.categories.find((category) => category.id == id);
      return category && category.name;
    },
    getMaterialName(id) {
      const material = this.materials.find((material) => material.id == id);
      return material && material.name;
    },
    getCurrencyName(code) {
      const currency = this.currencies.find(
        (currency) => currency.currency_code == code
      );
      return currency && currency.display_name;
    },
    switchEditMode() {
      // this.viewMode = false;
      if (this.editMode === true) {
        this.editMode = false;
        this.showOptions = true;
      } else {
        this.editMode = true;
        this.showOptions = false;
        store.commit("SET_INQUIRY_VIEW_MODE", false);
      }
      this.importTimeline = false;
      this.assignMode = false;
    },

    selectStyleNumber() {
      this.getStyleNumber();
      if (this.importTimeline === true) {
        this.importTimeline = false;
      } else {
        this.importTimeline = true;
      }
      this.editMode = false;
      this.assignMode = false;
    },

    updateChatStatus(status) {
      this.chatStatus = status;
    },
    // updateChatId(chatId) {
    //   this.chatID = chatId;
    //   this.getInquiry(this.inquiryId, this.chatID).then((response) => {
    //     this.model.files = response.data.files;
    //     this.ignoreFormEdit = true;
    //   });
    //   return chatId;
    // },
    updateFileList() {
      this.refreshFiles();
    },
    toggleOptions() {
      this.showOptions = true;
      this.assignMode = false;
      this.editMode = false;
    },
    assignSuppliers() {
      if (this.showOptions === true) {
        this.assignMode = true;
        this.showOptions = false;
      } else {
        this.showOptions = true;
        this.assignMode = false;
      }
    },
    alertBoxSet(edited, viewMode) {
      setLeaveAlert(false, edited);
      store.commit("SET_INQUIRY_VIEW_MODE", viewMode);
    },
    refreshSuppliers() {
      this.getInquiry(this.inquiryId).then((response) => {
        this.assignedSuppliers = [];
        response.data.suppliers &&
          response.data.suppliers.forEach((supplier) => {
            supplier.id = supplier.supplier_id;
            this.assignedSuppliers.push(supplier);
          });
        this.loading = false;
      });
    },
    getTraceStatus() {
      restAdapter
        .get(`/api/inquiry/${this.inquiryId}/trace/status`)
        .then((response) => {
          this.isAvailable = response.data.is_available;
          if (this.getIsAccountManager()) {
            this.isAvailable = true;
          }
          this.traceLayout = response.data.requested_layout;
        })
        .catch(() => {
          this.isAvailable = false;
        });
    },
    setPinnedMessages(messages) {
      this.pinnedMessages = messages;
    },
    toggleStatusWindow(value) {
      this.updateStatusOpen = value;
    },
    closeStatusWindow() {
      this.updateStatusOpen = false;
    },
    updateAssignedValueChain(value) {
      this.assignedValueChain = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.inquiry-form {
  justify-content: center;
  min-height: 779px;
}
.form-container::v-deep .md-steppers.md-theme-default {
  background-color: $paginateText;
}
.v-main {
  padding-top: 55px !important;
}

.lable_size {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: auto;
  padding-top: 20px;
  text-align: left;
  font-size: 20px;
  color: #582963;
  font-weight: bold;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.image-child-container {
  position: relative;
  width: fit-content;
  height: fit-content;
  border: 1px solid #442d65;
  border-radius: 7px;
  padding: 5px;
}
.add-img {
  position: absolute;
  border: 1px solid #5b3d87;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 3px;
  right: 3px;
  z-index: 2;
  background-color: white;
}
.add-img-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.54);
}
.image {
  object-fit: contain;
  max-height: 140px;
  max-width: 100%;
  border-radius: 7px;
}
.bar_color::v-deep .md-progress-bar-track:after {
  background-color: #582963 !important;
}

.md-progress-bar.md-theme-default.md-indeterminate,
.md-progress-bar.md-theme-default.md-query {
  background-color: #c6a8ec !important;
  background-color: var(--md-theme-default-primary-on-, #c6a8ec) !important;
}

.bar_color::v-deep .md-progress-bar-fill:after {
  background-color: #582963 !important;
}

.empty-layout-item {
  margin-top: -48px;
}

.service-block {
  margin-bottom: 10px;
  margin-top: -35px;
}

.service-block__item {
  margin-top: -20px;
  margin-bottom: -20px;
}

.checkbox-field {
  text-align: left;
}

.fieldset-subheader {
  padding-left: 0;
  font-size: 16px;
}

.form-label {
  text-align: right;
  color: $tableBorder;
}

.dropzone {
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
}

.form-value {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
}

.edit-mode-switch {
  text-align: right;
}

.set-default-text {
  font-size: 9px;
  font-weight: bold;
}

.spinner {
  position: relative;
  z-index: 999;
}

// .dropzone >>> .dz-preview .dz-progress {
//   opacity: 0 !important;
// }

.select-status {
  z-index: 999;
}

.cusname_color {
  color: #c6a8ec !important;
}

.subheading_color {
  background-color: $primaryDarkest;
  font-weight: 600;
  font-size: 16px;
}

.stepper_number_color::v-deep .md-stepper-header.md-active .md-stepper-number {
  background-color: $paginateText !important;
  color: $tableBorder !important;
  outline: 3px solid $tableBorder !important;
}

.list_color.md-selected::v-deep .md-list-item-content {
  color: #fa735f !important;
}

.list_color.md-selected::v-deep .md-list-item-text {
  color: $tableBorder !important;
}

.stepper_number_color::v-deep .md-stepper-number {
  background-color: $paginateText !important;
  color: $tableBorder !important;
  outline: 3px solid rgba(71, 48, 104, 0.41) !important;
}

.stepper_number_color::v-deep
  .md-stepper-header.md-done
  .md-stepper-number
  svg {
  fill: $success !important;
}

.stepper_number_color::v-deep .md-stepper-header.md-done .md-stepper-number {
  background-color: $paginateText !important;
  outline: 3px solid $success !important;
}

// .stepper_number_color::v-deep .md-stepper-header.md-active .md-stepper-number svg{
//   background-color: $paginateText !important;
//   color: $tableBorder !important;
//   outline: 3px solid rgba(71, 48, 104, 0.41) !important;
// }

.stepper_number_color::v-deep .md-steppers-navigation {
  background-color: #f6f6fb;
  border-radius: 5px;
  box-shadow: none;
}

.stepper_number_color::v-deep .md-steppers-wrapper {
  background-color: none;
}

.md-button.md-raised:not([disabled]).md-primary {
  background-color: $secondaryDarkest !important;
  border-color: $secondaryDarkest !important;
}

.md-field.md-theme-default.md-focused label {
  color: $tableBorder !important;
}

.md-field.md-theme-default:before {
  background-color: $tableBorder !important;
}

.md-field.md-theme-default.md-has-textarea:before {
  border-color: $tableBorder !important;
  background-color: transparent !important;
}

.md-theme-default a:not(.md-button) {
  color: $primaryDark !important;
}

.active {
  color: $primaryDark !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  background-color: #ffffff !important;
}

.v-tab:not(.v-tab--active) {
  color: $primaryDark !important;
  font-weight: 500 !important;
  opacity: 0.35 !important;
  text-decoration: none !important;
}

.v-tab:hover {
  background-color: #a89caa;
  cursor: pointer;
}

.active_color {
  border-color: #c6a8ec !important;
  color: #c6a8ec;
}

.inactive_color {
  border-color: $dangerBackground !important;
  color: $dangerBackground;
}

.completed_color {
  border-color: $spinnerStroke !important;
  color: $spinnerStroke;
}

.md-toolbar.md-theme-default.md-primary {
  background-color: $tableBorder !important;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
  box-shadow: none;
}
.package-content {
  padding: 15px 50px 18px 54px;
  color: $tableBorder;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.grand_parent {
  padding: 0px;
}

.card_size {
  width: 500px;
  height: 100px;
  margin: 20px;
  font-size: 22px !important;
  text-align: center !important;
  border: 2px solid $secondaryDarkest !important;
  color: $secondaryDarkest;
  border-radius: 20px;
  padding-top: 30px;
}

.delete_card_size {
  width: 500px;
  height: 100px;
  margin: 20px;
  font-size: 22px !important;
  text-align: center !important;
  border: 2px solid $dangerBackground !important;
  color: $dangerBackground;
  border-radius: 20px;
  padding-top: 30px;
}

.card_size .md-icon {
  font-size: 40px !important;
  margin-right: 20px;
  color: $secondaryDarkest;
}

.delete_card_size .md-icon {
  font-size: 40px !important;
  margin-right: 20px;
  color: $dangerBackground;
}

.service_card_container {
  margin: 0px;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 0;
}

.timeline_card_container {
  margin: 0px;
  margin-top: 25px;
}

.v-card {
  margin-top: 25px;
}

.service_buttons_container {
  padding-top: 50px;
}

.service_button_card {
  margin: 20px;
}

.button_card_items {
  display: flex;
  align-items: center;
  width: 100%;
}

.button_card_icon {
  float: left;
  margin: 0 28px;
}

.button_icon .v-icon {
  font-size: 33px;
  color: $tableBorder !important;
}

.update_status_container {
  position: relative;
}

.update_status_dropdown {
  position: absolute;
  z-index: 8;
  width: 455px;
  left: 0;
  right: 0;
  margin: -20px auto;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.md-icon {
  font-size: 40px !important;
  margin-right: 20px;
  color: $secondaryDarkest;
}

.error_message {
  padding: 200px;
  color: $danger;
  font-size: 18px;
}

.media-size {
  height: 150px;
  width: 100%;
}

.media-size-container {
  min-width: 10px;
  width: 100%;
}

.objectFit {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.overflow {
  max-width: 255px;
  display: inline-block;
  vertical-align: middle;
}

.card-actions {
  justify-content: center;
}

.inquiry-details-view {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 52vh;
}

.tabs {
  color: $light;
}

.tabs-items {
  background-color: $light;
  margin-bottom: 20px;
}

.tabs-slider {
  display: none;
}

.action-buttons {
  min-width: 25%;
}

.timeline_container {
  box-shadow: none !important;
  background-color: $paginateText !important;
}

.attachments-label {
  text-align: left;
  color: #442d65;
  font-weight: 600;
}

.dropzone::v-deep {
  background-color: $paginateBackground;
  border: 0.5px dashed #442d65;
  color: $tableBorder;

  .dz-preview .dz-progress {
    opacity: 0 !important;
  }
  .dz-error-message {
    display: none !important;
  }
  .dz-preview .dz-details {
    background-color: rgb(198, 168, 236, 0.7) !important;
  }
  .dz-preview .dz-remove {
    color: $secondaryDarkest !important;
  }
}

.btn-color {
  background-color: $tableBorder !important;
  border-radius: 3px;
  color: $paginateText !important;
  padding: 3px;
  margin: 0px 10px 2px 2px;
}

.info-container {
  width: 70%;
  margin: auto;
  padding: 20px;
}

.radio-group {
  margin-top: 0px !important;
}

.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}
.theme--light.v-sheet.v-list {
  background-color: #f3edf7 !important;
  text-align: left;
}

.back_btn_class {
  width: 149px;
  height: 36px;
  margin: 10px 0px 15px 0px;
  margin-left: 17px;
  background-color: rgba(71, 48, 104, 0.1) !important;
  border-radius: 81.2308px;
}

.back_btn_class:hover {
  cursor: pointer;
}

.container-alignment {
  margin-top: -10px;
}

.text_color {
  justify-content: center !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 13.7378px;
  line-height: 116.1%;
  display: flex;
  align-items: center;
  color: $primaryDark !important;
}
.move-collection-title {
  height: 36px;
  margin: 0px 0px 21px 0px;
  background-color: rgb(231, 237, 237) !important;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 40px;
}
.title-text {
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-weight: 600;
  font-size: 18px !important;
  padding: 10px;
  color: $primaryDark !important;
}
.duplicate-options-container {
  display: flex;
  justify-content: center;
}
.duplicate-options {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 1300px) {
  .back_btn_class {
    margin: 1px 1px 31px -15%;
  }
}

::v-deep .v-messages__message {
  margin-left: -12px !important;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.search-content {
  margin: 10px 0px 30px 0px;
  padding: 12px;
}

.collection-search-bar {
  text-align: right;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0px 3px 25px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.request-container {
  background: white;
  border-radius: 12px;
}

.request-item {
  text-align: left;
  padding: 20px 40px;
  color: #473068;
}

.warning-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.warning-message {
  width: 350px;
  color: #ef5753;
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
